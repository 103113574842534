<template>
  <v-app>
    <v-form ref="form" class="login-form">
      <div class="text-center">
        <img :src="require('@/assets/mkgest-icon.png')" class="mb-6" style="width: 120px;" />
      </div>
      <div class="display-2 font-weight-light text-center login-title">
        MKGest - Admin
      </div>
      <v-col cols="12">
        <v-text-field v-model="email" label="Email" required />
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="password" label="Password" required
          :append-icon="showPasssword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPasssword ? 'text' : 'password'"
          @click:append="showPasssword = !showPasssword" />
      </v-col>
      <v-col v-if="failed" cols="12" class="error--text text-center">
        {{ failed }}
      </v-col>
      <v-col cols="12 text-right">
        <v-btn color="secondary" class="mr-0" @click="submit()">
          Login
        </v-btn>
      </v-col>
    </v-form>
  </v-app>
</template>
  
<script>
import Api from '../../services/Api'
import Utils from '../../services/Utils'

export default {
  data() {
    return {
      email: '',
      password: '',
      showPasssword: false,
      failed: false,
    }
  },
  beforeMount: function () {
    try {
      if (sessionStorage.getItem('login')) {
        const user = JSON.parse(sessionStorage.getItem('login'))
        this.email = user.email
        this.password = user.password
      }
    } catch (error) { }
    sessionStorage.removeItem('login')
  },
  methods: {
    submit: function () {
      const self = this
      const user = {
        email: this.email,
        password: this.password,
      }
      this.$isLoading(true)
      Api.login(user, function (response) {
        if (response.success) {
          self.finish(user)
        } else {
          self.$isLoading(false)
          self.failed = response.message
        }
      })
    },
    finish: function (user) {
      const self = this
      Api.getStrings(function (response) {
        self.$isLoading(false)
        if (response.success) {
          sessionStorage.setItem('login', JSON.stringify(user))
          window.strings = response.data
          self.$router.replace({ path: '/home' })
        } else {
          self.failed = response.message
        }
      })
    },
  }
}
</script>